import axios from 'axios';
import { Toast, Indicator } from 'mint-ui';
import { baseUrl } from './baseUrl';
import {
  getOpenId,
  getAppKey,
  getAppSign,
  getFingerPrint,
  getAccessToken,
  getQueryString
} from '../lib/utils';

function handleRequest (res) {
  if (res.status === 200) {
    return res.data;
  }
  Toast({
    message: '网络错误',
    duration: 2000,
  });
  Indicator.close()
  return false;
}

function handleRequestData (res) {
  if (res.status === 1000) {
    return res.data;
  }
  Toast({
    message: res.desc,
    duration: 2000,
  });
  Indicator.close()
  return false;
}

function handleError(err){
  Toast({
    message: err,
    duration: 2000,
  });
  Indicator.close()
}

const http = async (options, handleErrorFn) => {
  const timestamp = new Date().getTime();
  const app_sign = getAppSign(options.method, options.url, timestamp);
  const access_token = getAccessToken();
  const app_key = getAppKey();
  const open_uid = getOpenId();

  const fingerprint = await getFingerPrint()
  // const fingerprint = 'c2ebf1f269a8a493d66ecac943642d07'

  return axios({
    url: `${baseUrl(options.baseType)}${options.url}`,
    method: options.method ? options.method : 'GET',
    params: {
      timestamp,
      app_sign,
      open_uid,
      access_token,
      app_key,
      fingerprint,
      device: getQueryString('channel')+fingerprint,
      ...options.params,
    },
    data: {
      ...options.data,
    },
    timeout: 30000
  }).then(handleRequest).then(handleRequestData).catch(handleErrorFn || handleError);
};

export default http;
