import http from '../../api/http'

export const getInfo = params => http({
    url: '/api/h5/today_history/detail/v2',
    method: 'GET',
    params,
    baseType: 'peacock'
});

export const getArticlePost = params => http({
    url: '/api/h5/today_history/article/detail',
    method: 'GET',
    params,
    baseType: 'peacock'
});

