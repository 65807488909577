const env = /(location|192\.168|test|localhost)/.test(window.location.href)
  ? 'test'
  : 'prod';

const baseUrlDic = {
  zhwnl: {
    test: 'http://test-zhwnl.zhwnl.cn',
    // test: 'https://v2-client.suishenyun.cn',
    prod: 'https://v2-client.suishenyun.cn'
  },
  lizhi: {
    test: 'http://test-lz.zhwnl.cn',
    prod: 'https://client-lz.rili.cn'
  },
  mock: {
    test: 'http://mock.etouch.cn/mock/601904b01042733b2ef3ff30',
    prod: 'http://mock.etouch.cn/mock/601904b01042733b2ef3ff30'
  },
  peacock: {
    test: 'http://test-pc.zhwnl.cn/peacock',
    prod: 'http://pc.suishenyun.net/peacock'
  },
  ecalender: {
    test: 'http://test-lz.zhwnl.cn/Ecalender',
    prod: 'http://zhwnlapi.etouch.cn/Ecalender'
  }
}
// 业务线类型 默认是中华万年历  还有peacock 和 lizhi
export function baseUrl (type = 'zhwnl') {
  return baseUrlDic[type][env]
} 